<template>
  <div class="updateApp">
    <div >
      <my-membership></my-membership>
    </div>
    <el-row class="form">
      <el-col :span="8">
        <div style="margin: 1px"></div>
      </el-col>

      <el-col :span="8">
        <div>
          <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
                   class="demo-ruleForm">
            <el-form-item label="旧密码" prop="oldPassword">
              <el-input v-model.trim="ruleForm.oldPassword" type="password"></el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="pass">
              <el-input type="password" v-model.trim="ruleForm.pass" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="checkPass">
              <el-input type="password" v-model.trim="ruleForm.checkPass" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="form-btn" type="primary" @click="submitForm('ruleForm')">修改</el-button>
              <el-button @click="resetForm('ruleForm')">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
      <el-col :span="8"><div style="margin: 1px"></div></el-col>
    </el-row>
  </div>
</template>

<script>
import MembershipBar from '../common/MenberShipBar'
import api from '@/api/user.js'

export default {
  components: {
    'my-membership': MembershipBar
  },
  name: "updateUserInfo",
  data() {
    var validatePass = (rule, value, callback) => {
      // if (value === '') {
      //   callback(new Error('请输入新的密码'));
      // } else {
      //   if (this.ruleForm.checkPass !== '') {
      //     this.$refs.ruleForm.validateField('checkPass');
      //   }
      //   callback();
      // }
        if (value == null || value == "") {
            callback(new Error('请输入新的密码'));
        }

        if (value.length > 15 || value.length < 8) {
            callback(new Error('密码长度应为8-15个字符'));
        }

        if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,15}$/.test(value)) {
            callback(new Error('密码必须由数字、字母两种字符组成，长度在8-15位之间'));
        }

        if (this.ruleForm.checkPass !== '') {
            this.$refs.ruleForm.validateField('checkPass');
        }

        callback();
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入新的密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    var validateOld = (rule, value, callback) => {
        value = value + "";
        if (value == null || value == "") {
            callback(new Error('请输入原密码'));
        }
        if (value.length > 15 || value.length < 8) {
            callback(new Error('密码长度应为8-15个字符'));
        }
        if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,15}$/.test(value)) {
            callback(new Error('密码必须由数字、字母两种字符组成，长度在8-15位之间'));
        }

        callback();
    };
    return {
      ruleForm: {
        pass: '',
        checkPass: '',
        oldPassword: ''
      },
      rules: {
        pass: [
          {validator: validatePass, trigger: 'blur'}
        ],
        checkPass: [
          {validator: validatePass2, trigger: 'blur'}
        ],
        oldPassword: [
          {validator: validateOld, trigger: 'blur'}
        ]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
            let userInfo = this.$store.state.userInfo.userInfo;
            api.modifyPwd({
                'userAuthId': userInfo.saasUserId,
                'userAuthAccount': userInfo.userAuthAccount,
                'userAuthCredential': this.ruleForm.pass,
                'oldPassword': this.ruleForm.oldPassword
            }).then(res => {
                if(res.code == 200) {
                    this.$message.success('修改成功');
                    //清空cookie，跳转登录
                    this.$store.commit("logout")
                    this.$router.push({path: '/login'})
                }else {
                    this.$message.error('修改失败：' + res.message);
                }
            }, (res) => {
                this.$message.error('修改失败：' + res.message);
            })
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>

<style scoped>
.updateApp {
  position: relative;
  height: 920px;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
}
.form{
  margin-top: 50px;
}
.el-form-item {
  margin-bottom: 57px;
}

@media screen and (max-width: 800px){
    /deep/ .el-form-item {
        display: flex;
        margin-left: -100px;
    }

    /deep/ .el-form-item__label {
        width: 100px !important;
    }

    /deep/ .el-form-item__content {
        margin-left: 0px !important;
    }
    .form-btn {
        margin-left: 70px;
    }
}
</style>
