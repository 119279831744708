import http from "../utils/axios";
import {get,post} from "@/utils/axios"
//登录
// const login = data => {
//     return http({
//         // url: "api-common/user/login",//接口
//         method: "post",
//         data: data
//     });
// };
const login = data => get('api-seller/apiconsole/user/loginsign', data);
//发送验证码
// const sendVerificationCode = p => get('api-common/user/sendVerificationCode', p);
const sendVerificationCode = p => get('api-seller/apiconsole/user/loginMail', p);
//注册
// const register = data => post("api-common/user/register",data);
const register = data => post("api-seller/apiconsole/user/register",data);
//修改密码
const modifyPwd = data => post("api-common/user/modifyPassword", data)

// 华为重定向
const redirect = data => post("api-seller/apiconsole/user/redirect",data);

// 华为请求验签
const verification = data => post("api-seller/apiconsole/user/verification",data);

export default {
    login,
    register,
    sendVerificationCode,
    modifyPwd,
    redirect,
    verification
}
